jQuery.extend( jQuery.fn.pickadate.defaults, {
  monthsFull: [ 'leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec' ],
  monthsShort: [ 'led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro' ],
  weekdaysFull: [ 'neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota' ],
  weekdaysShort: [ 'ne', 'po', 'út', 'st', 'čt', 'pá', 'so' ],
  today: 'dnes',
  clear: 'vymazat',
  close: null,
  firstDay: 1,
  format: 'd.m.yyyy'
});
